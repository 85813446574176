angular
    .module('CareGuard')
    .controller('claimController', claimController);

claimController.$inject = [
    '$state',
    '$timeout',
    '$toastr',
    '$q',
    'webStorageService',
    'claimService',
    'claimDetailStatusId',
    'lookupService',
    'accountService',
    'bankingService',
    'documentService',
    'documentTypeId',
    'payeeService',
    'providerService',
    'memberID',
    'memberService',
    'memberFlagsService',
    'utilService',
    'getUrlService',
    'LxDialogService',
    'billReviewService'];

function claimController(
    $state,
    $timeout,
    $toastr,
    $q,
    webStorage,
    claimService,
    claimDetailStatusId,
    lookupService,
    accountService,
    bankingService,
    documentService,
    documentTypeId,
    payeeService,
    providerService,
    memberID,
    memberService,
    memberFlagsService,
    utilService,
    getUrlService,
    LxDialogService,
    billReviewService) {

    let vm = this;

    vm.claims = [];
    vm.lookupdata = {};
    vm.member = {};
    vm.memberflags = [];
    vm.newClaim = {};
    vm.filterData = {};
    vm.paginationControls = {};
    vm.checkedAll = false;
    vm.searchPayeeBy = null;
    vm.showEditPanel = false;
    vm.enteredClaimStatus = {};
    vm.defaultClaimStatus = {};
    vm.availablePageSizes = [5, 10, 20, 50];
    vm.currentState = $state.current.name;
    let claimStatusMap;
    let billReviewVendorMap;

    const filterWebStorageKey = 'claimsFilterConfig';
    const webStorageType = 'localStorage';
    const storedFilterConfig = webStorage.get(filterWebStorageKey, webStorageType);
    vm.userId = accountService.getUserId();
    const newClaimDialogId = 'newClaimDialog';

    vm.filterClaims = filterClaims;
    vm.resetFilters = resetFilters;
    vm.changePage = changePage;
    vm.navigate = navigate;
    vm.getUrlFromState = getUrlFromState;
    vm.toggleCheckAll = toggleCheckAll;
    vm.getImageFile = getImageFile;
    vm.zeroPay = zeroPay;
    vm.hasRole = hasRole;
    vm.selectPayee = selectPayee;
    vm.exportClaims = exportClaims;

    vm.openNewClaimPopup = openNewClaimPopup;
    vm.closeNewClaimPopup = closeNewClaimPopup;

    const defaultSortingDescOrderForNumber = ['id', 'submittedUNCAmount', 'billedAmount', 'feeAmount', 'payableAmount'];
    vm.changeSort = changeSort;

    (() => {
        initializePagination();
        vm.searchPayeeQuery = buildPayeeSearchRoute();

        const promises = [
            getClaimTypeData(),
            getClaimStatusData(),
            getClaimDetailStatusData(),
            getBillReviewVendors(),
        ];

        if (memberID) {
            vm.member.MemberID = memberID;
            promises.push(
                getMemberData(memberID),
                getMemberFlagData(memberID),
                getMemberAsPayeeData(memberID)
            )
        }

        $q.all(promises).then(() => {
            applyStoredFilterConfig();
        });
    })();

    function getBillReviewVendors() {
        billReviewService.getVendors().then(({ data: vendors }) => {
            vm.billReviewVendors = vendors.filter(vendor => vendor.isActive);
            billReviewVendorMap = buildBillReviewVendorMap(vendors);
        })
    }

    function buildBillReviewVendorMap(vendors) {
        return vendors.reduce((map, obj) => map.set(obj.id, obj.name), new Map());
    }

    function getClaimTypeData() {
        lookupService.getLookUp('claimType').then(({ Data: types }) => {
            vm.lookupdata.billTypes = types;
        });
    }

    function getClaimStatusData() {
        claimService.getClaimStatuses().then(({ data: statuses }) => {
            vm.claimStatuses = statuses;
            claimStatusMap = buildClaimStatusMap(statuses);
        });
    }

    function buildClaimStatusMap(statuses) {
        return statuses.reduce((map, obj) => map.set(obj.id, obj.status), new Map());
    }

    function getClaimDetailStatusData() {
        claimService.getClaimDetailStatuses().then(({ data: statuses }) => {
            vm.claimDetailStatuses = statuses;

            vm.zeroPayStatuses = statuses.filter(status => status.category.isZeroPay);
        });
    }

    function getMemberData(memberID) {
        memberService.getById(memberID).then(({ Data: member }) => {
            vm.member = member;
        });
    }

    function getMemberFlagData(memberID) {
        memberFlagsService.getMemberFlags(memberID).then(({ Data: flags }) => {
            vm.memberflags = flags;
        });
    }

    function getMemberAsPayeeData(memberID) {
        payeeService.getPayeeByMemberId(memberID).then(payee => {
            if (!payee) return;

            vm.member.payee = payee;
        });
    }

    function initializePagination() {
        vm.paginationControls = {
            pageNumber: 1,
            pageSize: 50,
            totalRows: 0
        };
    }

    function buildPayeeSearchRoute() {
        return `${payeeService.searchPayeesRoute()}?searchBy=`;
    }

    function filterClaims(isNewSearch = false) {
        if (vm.searchPayeeBy && !vm.filterData.PayeeID) {
            $toastr.show('No payee selected. Field has been reset', 'warning');
            vm.searchPayeeBy = null;
            vm.filterData.PayeeID = null;
        }
        if (!validateFilters()) return;
        if (isNewSearch) resetPage();

        getMemberIdsFromFilters().then(result => {
            vm.filterData.MemberIDs = result?.data ?? result;
            vm.filterData.ClaimIDs = buildClaimIdArray(vm.filterData?.ClaimIDs);

            const params = buildClaimParamObject();

            vm.isDataLoading = true;

            claimService.searchClaims(params)
                .then(({ data, data: { items: claims } }) => {
                    if ((Array.isArray(data) && !data.length) || !claims.length) {
                        vm.claims = [];
                        initializePagination();
                        return;
                    }

                    setPaginationValues(data);
                    $q.all(getAdditionalDataPromises(claims)).then(results => {
                        vm.claims = buildClaimAssignment({ claims, results });
                        vm.checkedAll = false;
                        storeFiltersInCache();
                    });
                }).finally(() => {
                    vm.isDataLoading = false;
                });
        });
    }

    function validateFilters() {
        if (!hasSelectedFilters()) {
            $toastr.show(`Must use at least one filter.`, `warning`);
            return false;
        }

        if (vm.filterData.MemberNumber && !vm.filterData.MemberNumber.match(/^[a-zA-Z0-9]{7}$/)) {
            $toastr.show(`Member number must contain 7 characters.`, `warning`);
            return false;
        }

        if (vm.filterData.MemberName && vm.filterData.MemberName.split(' ').filter(Boolean).length < 2) {
            $toastr.show(`Member name must include both a first name and a last name.`, `warning`);
            return false;
        }

        if (vm.filterData.ClaimNumber && (vm.filterData.ClaimNumber.length < 8 || vm.filterData.ClaimNumber.length > 30)) {
            $toastr.show(`Claim number must contain between 8 and 30 characters.`, `warning`);
            return false;
        }

        if (vm.filterData.ClaimIDs && !buildClaimIdArray(vm.filterData.ClaimIDs).length) {
            $toastr.show(`Invalid format for entered claim ids.`, `warning`);
        }

        return true;
    }

    function resetPage() {
        vm.paginationControls.pageNumber = 1;
        vm.paginationControls.totalRows = 0;
    }

    function getMemberIdsFromFilters() {
        if (vm.member.MemberID)
            return $q.when([vm.member.MemberID]);

        if (!vm.filterData.MemberNumber && !vm.filterData.MemberName) return $q.when(null);

        let filters = {};

        if (vm.filterData.MemberNumber) {
            filters.memberNumber = vm.filterData.MemberNumber;
        }

        if (vm.filterData.MemberName) {
            const names = vm.filterData.MemberName.split(' ').map(name => name.trim());
            filters.firstName = names[0];
            filters.lastName = names[1];
        }

        return memberService.getMemberIdsByNumberOrName(filters);
    }

    function buildClaimParamObject() {
        const requestedFields = [
            'id',
            'memberId',
            'payeeID',
            'claimStatusID',
            'claimStatus',
            'claimNumber',
            'billType',
            'billTypeID',
            'serviceDate',
            'receivedDate',
            'billReviewSentDate',
            'billReviewReceivedDate',
            'submittedUNCAmount',
            'billedAmount',
            'payableAmount',
            'feeAmount',
            'paidDate',
            'isReconsideration',
            'stopWhenReceived',
            'billReviewVendorID'
        ];
        return { ...vm.filterData, ...vm.paginationControls, fields: requestedFields.join(','), orderBy: vm.filterData.SortColumn ?? '-receivedDate', isAmethyst: false };
    }

    function buildClaimIdArray(field) {
        if (!field) return null;
        if (Array.isArray(field)) return field;

        return field.trim().replace(/![0-9]/g, ',').split(',').filter(Boolean);
    }

    function getAdditionalDataPromises(claims) {
        let promises = [];

        const claimIds = claims.map(claim => claim.id);
        const memberIds = [...new Set(claims.map(claim => claim.memberID))];

        if (!vm.filterData.MemberID) {
            promises.push(memberService.getMembersByIds({ ids: memberIds, fields: `id,memberNumber,fullName,insuranceType` }));
        }

        const payeeIds = [...new Set(claims.map(claim => claim.payeeID))];
        promises.push(payeeService.getPayeesByIds({ ids: payeeIds, fields: `id,name,npiNumber` }));

        promises.push(providerService.getProvidersByClaimIds({ ids: claimIds, fields: `claimId,firstName,lastName,providerType,npiNumber` }));
        promises.push(bankingService.getCurrentBalanceTotalsByMemberIds({ ids: memberIds, fields: `memberId,totalBankBalance` }));
        promises.push(documentService.getDocumentsByClaimIds({ ids: claimIds, fields: `id,claimId,documentType,documentTypeId,physicalPath` }));
        promises.push(documentService.getClaimEorsByClaimIds(claimIds));
        
        return promises;
    }

    function getAdditionalDataPromisesByBulk(claims) {
        let promises = [];

        const claimIds = claims.map(claim => claim.id);
        const memberIds = [...new Set(claims.map(claim => claim.memberID))];

        if (!vm.filterData.MemberID) {
            promises.push(memberService.getMembersByIdsByBulk({ ids: memberIds, fields: `id,memberNumber,fullName,insuranceType` }));
        }

        const payeeIds = [...new Set(claims.map(claim => claim.payeeID))];
        promises.push(payeeService.getPayeesByIdsByBulk({ ids: payeeIds, fields: `id,name,npiNumber` }));
        promises.push(providerService.getProvidersByClaimIds({ ids: claimIds, fields: `claimId,firstName,lastName,providerType,npiNumber` }));
        promises.push(bankingService.getCurrentBalanceTotalsByMemberIds({ ids: memberIds, fields: `memberId,totalBankBalance` }));
        promises.push(documentService.getDocumentsByClaimIdsByBulk({ ids: claimIds, fields: `claimId,documentType,physicalPath` }));
        promises.push(documentService.getClaimEorsByClaimIds(claimIds));
        
        return promises;
    }

    function hasSelectedFilters() {
        return Object.values(vm.filterData)?.filter(Boolean)?.length || vm.searchPayeeBy || vm.member.MemberID;
    }

    function setPaginationValues({ totalRows, totalPages, pageNumber, pageSize }) {
        vm.paginationControls.totalRows = totalRows;
        vm.paginationControls.totalPages = totalPages;
        vm.paginationControls.pageNumber = pageNumber;
        vm.paginationControls.pageSize = pageSize ?? vm.paginationControls.pageSize;
    }

    function buildClaimAssignment({ claims, results }) {
        let members, payees = [];
        let promiseIndex = 0;

        if (!vm.filterData.MemberID) {
            members = results[promiseIndex++].data;
        }

        payees = results[promiseIndex++].data;

        const providers = results[promiseIndex++].data
            .filter(provider => ['Pharmacy', 'Location'].includes(provider.providerType))
            .sort((provider1, provider2) => {
                provider2.claimID - provider1.claimID ||
                    provider1.firstName.localeCompare(provider2.firstName)
            });
        const balances = results[promiseIndex++].data;
        const documents = mergeMultipleValuesToArray(results[promiseIndex++].data);
        const eors = results[promiseIndex++].data;
        const currentEors = eors.filter(e => e.isCurrent == 1)

        const claimMap = buildClaimMap([...providers, ...documents]);
        const memberMap = buildMemberMap([...members, ...balances]);

        return claims.map(claim => {
            var claimData = claimMap.get(claim.id);
            claim.claimId = claim.id;
            claim.claimStatus = claimStatusMap.get(claim.claimStatusID);
            claim.memberNumber = memberMap.get(claim.memberID)?.memberNumber;
            claim.memberName = memberMap.get(claim.memberID)?.fullName;
            claim.payeeName = payees.find(payee => payee.id === claim.payeeID)?.name;
            claim.providerName = getProviderName({ map: claimMap, id: claim.id });
            claim.bankAmount = memberMap.get(claim.memberID)?.totalBankBalance;
            claim.billFile = claimData?.documents?.find(doc => ![documentTypeId.EOR, documentTypeId.EORState].includes(doc.documentTypeID))?.physicalPath;
            var eorFile = currentEors.find(e => e.claimId == claim.id);
            claim.eorFileId = eorFile?.documentId;
            claim.eorFile = claimData?.documents?.find(doc => doc.id == claim.eorFileId)?.physicalPath;
            claim.eorStateFileId = claimData?.documents?.find(doc => doc.documentTypeID === documentTypeId.EORState)?.id;
            claim.eorStateFile = claimData?.documents?.find(doc => doc.documentTypeID === documentTypeId.EORState)?.physicalPath;
            claim.insuranceType = memberMap.get(claim.memberID)?.insuranceType;
            claim.billReviewVendor = billReviewVendorMap.get(claim.billReviewVendorID);
            return claim;
        });
    }

    function mergeMultipleValuesToArray(documents) {
        return Array.from(new Set(documents.map(doc => doc.claimID)))
            .map(claimID => {
                return {
                    claimID,
                    documents: documents.filter(doc => doc.claimID === claimID).map(doc => doc)
                }
            });
    }

    function buildClaimMap(arr) {
        return arr.reduce((map, obj) => map.set(obj.claimID, Object.assign(map.get(obj.claimID) || {}, obj)), new Map());
    }

    function buildMemberMap(arr) {
        return arr.reduce((map, obj) => map.set(obj.id ?? obj.memberID ?? obj.memberId, Object.assign(map.get(obj.id ?? obj.memberID ?? obj.memberId) || {}, obj)), new Map());
    }

    function getProviderName({ map, id }) {
        let providerName;

        if (!map.get(id)?.firstName)
            return providerName;

        providerName = map.get(id).firstName;

        if (map.get(id)?.lastName)
            providerName = `${providerName} ${map.get(id).lastName}`;

        return providerName;
    }

    function storeFiltersInCache() {
        webStorage.set(filterWebStorageKey, { ...vm.filterData, ...vm.paginationControls, selectedPayee: vm.searchPayeeBy, userId: vm.userId }, true);
    }

    function exportClaims() {
        const params = buildClaimParamObject(vm.filterData);
        params.pageSize = Math.round(params.totalRows * 1.10);

        vm.isDataLoading = true;
        claimService.searchClaims(params)
            .then(({ data, data: { items: claims } }) => {
                if ((Array.isArray(data) && !data.length) || !claims.length) {
                    vm.claims = [];
                    initializePagination();
                    return;
                }

                $q.all(getAdditionalDataPromisesByBulk(claims)).then(results => {
                    const bulkClaims = buildClaimAssignment({ claims, results });

                    documentService.exportClaimsToExcel(bulkClaims).then(response => {
                        utilService.processResponse({ response });
                    });
                });
            }).finally(() => {
                vm.isDataLoading = false;
            });
    }

    function toggleCheckAll() {
        vm.claims.forEach(claim => {
            claim.Checked = vm.checkedAll;
        });
    }

    function changePage(pageNumber) {
        if (!pageNumber || pageNumber > vm.paginationControls.totalPages) return;

        vm.paginationControls.pageNumber = pageNumber;
        filterClaims();
    }

    vm.changePageNumber = function () {
        resetPageNumber();
    }

    function resetPageNumber() {
        vm.paginationControls.pageNumber = 1;
    }

    function navigate(state, params) {
        $timeout(() => $state.go(state, params), 0);
    }

    function getUrlFromState(stateName, params) {
        return getUrlService.get(stateName, params);
    }

    function resetFilters() {
        initializePagination();
        vm.filterData = {};
        vm.searchPayeeBy = null;
        vm.claims = [];

        webStorage.remove(filterWebStorageKey, webStorageType);
    }

    vm.validRolesForPayeeSearch = function () {
        return accountService.isInRole('CareGuardFinance') || accountService.isInRole('CareGuard');
    }

    function selectPayee(payee) {
        const newPayee = payee?.originalObject;
        if (!payee || !Object.keys(newPayee)?.length) {
            vm.filterData.PayeeID = null;
            return;
        }

        vm.filterData.PayeeID = newPayee.id;
        vm.searchPayeeBy = newPayee.name;
    }

    function getImageFile(memberNumber, filename) {
        documentService.downloadMemberAzureBlobDocument({ memberNumber, filename }).then(response => {
            if (Array.isArray(response?.data) && !response?.data?.length) {
                $toastr.show(`Document not found.`, `warning`);
                return;
            }
            utilService.processResponse({ response });
        });
    }

    function zeroPay() {
        if (!vm.newClaimDetailStatusID) return;

        const selectedClaims = vm.claims.filter(claim => claim.Checked);

        if (!selectedClaims.length) {
            $toastr.show(`No claims have been selected.`, `warning`);
            return;
        }

        for (let claim of selectedClaims) {
            if (!claimService.canBeZeroPaid(claim)) {
                $toastr.show(`Claim ${claim.id} can't be zero paid. Please unselect.`, `warning`);
                return;
            }
        }

        const ids = selectedClaims.map(claim => claim.id);
        claimService.zeroPayClaims({ ids, statusId: vm.newClaimDetailStatusID }).then(() => {
            removePayableFromSelectedClaims();
            updateClaimStatusOfSelectedClaims();

            $toastr.show(`Zero pay complete.`, `success`);
            return deleteEORDocuments(selectedClaims);
        }).finally(() => {
            vm.newClaimDetailStatusID = null;
            removeCheckedFromSelectedClaims();
        });
    }

    function removePayableFromSelectedClaims() {
        if (isZeroPay(vm.newClaimDetailStatusID)) {
            vm.claims.filter(claim => claim.Checked).forEach(claim => {
                claim.payableAmount = 0;
                claim.feeAmount = 0;
                return claim;
            });
        }
    }

    function updateClaimStatusOfSelectedClaims() {
        vm.claims.filter(claim => claim.Checked).forEach(claim => {
            claim.claimStatus = `Ready For Payment`;
            return claim;
        });
    }

    function deleteEORDocuments(selectedClaims) {
        const eorPromises = selectedClaims.filter(claim => claim.eorFileId).map(claim => {
            return billReviewService.setCurrentEor(claim.id, claim.eorFileId, false);
        });

        return $q.all(eorPromises).then(() => {
            selectedClaims.forEach(claim => {
                claim.eorFileId = null;
                claim.eorFile = null;
                claim.eorStateFileId = null;
                claim.eorStateFile = null;
            });
        });
    }

    function removeCheckedFromSelectedClaims() {
        vm.claims.filter(claim => claim.Checked).forEach(claim => {
            claim.Checked = false;
            return claim;
        });

        if (vm.checkedAll)
            vm.checkedAll = false;
    }

    function applyStoredFilterConfig() {
        if (hasInvalidCacheConfiguration()) return;

        const { selectedPayee, userId, pageNumber, pageSize, totalRows, totalPages, ...filters } = storedFilterConfig;

        vm.filterData = filters;
        if (vm.member.MemberID) {
            vm.filterData.MemberNumber = vm.member.MemberNumber;
            vm.filterData.MemberIDs = filters.MemberIDs?.filter(id => id === memberID);
        }
        setPaginationValues({ totalRows, totalPages, pageNumber, pageSize });

        if (!hasSelectedFilters() && !selectedPayee) return;
        filterClaims();

        if (!selectedPayee) return;
        vm.searchPayeeBy = selectedPayee;
    }

    function hasInvalidCacheConfiguration() {
        if (!storedFilterConfig || storedFilterConfig.userId !== vm.userId) return true;

        if (storedFilterConfig?.MemberIDs?.length) {
            const isMemberMatch = storedFilterConfig?.MemberIDs?.some(member => member == memberID);
            if (!isMemberMatch)
                storedFilterConfig.MemberIDs = null;
            return !isMemberMatch;
        }

        return false;
    }

    function hasRole(role) {
        return accountService.isInRole(role);
    }

    function openNewClaimPopup() {
        LxDialogService.open(newClaimDialogId);
    }

    function closeNewClaimPopup() {
        vm.newClaim = {};
        return LxDialogService.close(newClaimDialogId);
    }

    function changeSort(column) {
        if (vm.filterData.SortColumn?.replace('-', '') == column) {
            vm.filterData.SortOrder = (vm.filterData.SortOrder == '' ? '-' : '')
            vm.filterData.SortColumn = vm.filterData.SortOrder + column;
        } else if (defaultSortingDescOrderForNumber.includes(column)) { // When sorting on number column, default sorting is desc
            vm.filterData.SortOrder = '-';
            vm.filterData.SortColumn = vm.filterData.SortOrder + column;
        } else {
            vm.filterData.SortColumn = column;
            vm.filterData.SortOrder = '';
        }
        filterClaims();
    };

    function isZeroPay(paramClaimDetailStatusId) {
        return vm.claimDetailStatuses.find(x => x.id == parseInt(paramClaimDetailStatusId)).category.isZeroPay;
    }
}